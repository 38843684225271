<template>
  <div v-if="energyFlowDirectionSensor" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="energyFlowDirectionSensor">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Basisdaten</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('manufacturer')"
            label-for="manufacturer"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="energyFlowDirectionSensor.manufacturer"
              type="text"
              :placeholder="$t('manufacturer')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('type')"
            label-for="type"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="energyFlowDirectionSensor.type"
              type="text"
              :placeholder="$t('type')"
            />
          </b-form-group>

          <legend>
            <strong>Dokumente</strong>
          </legend>

          <DropzoneUpload
            v-if="!isNew"
            :complete="isAttachmentUploadComplete"
            :parentId="energyFlowDirectionSensor.id"
            :uploadCallback="uploadEnergyFlowDirectionSensorAttachment"
            v-on:dropzone-upload:reset="isAttachmentUploadComplete = false"
          />

          <AttachmentList
            :attachments="energyFlowDirectionSensor.attachments"
            v-on:attachment-list:delete="onDeleteEnergyFlowDirectionSensorAttachment"
          ></AttachmentList>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showDeleteButton="buttons.showDeleteButton"
            :model="energyFlowDirectionSensor"
            modelEditRoute="EnergyFlowDirectionSensorEditPage"
            modelListRoute="EnergyFlowDirectionSensorsPage"
            modelRouteParamName="energyFlowDirectionSensorNumber"
            :updateCallback="updateEnergyFlowDirectionSensor"
            :createCallback="createEnergyFlowDirectionSensor"
            :deleteCallback="deleteEnergyFlowDirectionSensor"
            :fetchCallback="fetchEnergyFlowDirectionSensors"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import { yesNo } from '@/helpers/enums';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentList from '@/components/AttachmentList';
import ButtonBar from '@/components/ButtonBar.vue';

export default {
  name: 'EnergyFlowDirectionSensorEditPage',
  components: {
    Widget,
    Breadcrumbs,
    DropzoneUpload,
    AttachmentList,
    ButtonBar,
  },
  props: {
    energyFlowDirectionSensorNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: null,
      user: {},
      buttonsDisabled: false,
      isAttachmentUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getEnergyFlowDirectionSensor', 'getEnergyFlowDirectionSensorByNumber']),
    buttons() {
      return {
        showDeleteButton:
          this.energyFlowDirectionSensor && this.energyFlowDirectionSensor.number !== '_new'
            ? true
            : false,
      };
    },
    optionsYesNo() {
      return yesNo();
    },
    energyFlowDirectionSensor() {
      return this.getEnergyFlowDirectionSensorByNumber(this.number);
    },
    isNew() {
      return this.energyFlowDirectionSensor.id === '_new';
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        {
          name: this.$t('energyFlowDirectionSensor'),
          route: { name: 'EnergyFlowDirectionSensorsPage' },
        },
        {
          name: this.energyFlowDirectionSensor.number !== '_new' ? this.$t('edit') : this.$t('new'),
        },
      ];
    },
  },
  methods: {
    ...mapActions([
      'initEnergyFlowDirectionSensor',
      'fetchEnergyFlowDirectionSensors',
      'fetchEnergyFlowDirectionSensorByNumber',
      'updateEnergyFlowDirectionSensor',
      'createEnergyFlowDirectionSensor',
      'deleteEnergyFlowDirectionSensor',
      'deleteEnergyFlowDirectionSensorAttachment',
      'uploadEnergyFlowDirectionSensorAttachment',
    ]),
    isDisabled(e) {
      e.preventDefault();
    },
    onDeleteEnergyFlowDirectionSensorAttachment(event) {
      this.deleteEnergyFlowDirectionSensorAttachment({
        energyFlowDirectionSensorId: this.energyFlowDirectionSensor.id,
        attachmentId: event.attachmentId,
      });
    },
  },
  async mounted() {
    if (!this.energyFlowDirectionSensor && this.energyFlowDirectionSensorNumber) {
      this.number = this.energyFlowDirectionSensorNumber;
      this.fetchEnergyFlowDirectionSensorByNumber(this.number);
    } else {
      this.initEnergyFlowDirectionSensor();
      this.number = '_new';
    }
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadEnergyFlowDirectionSensorAttachment') {
          this.isAttachmentUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style scoped lang="scss">
.energyFlowDirectionSensorContacts {
  text-align: right;
}
.trash-contact {
  cursor: pointer;
}
</style>
